@use 'bs';

$grid-width: 5rem + 2.5rem * 25;
$small-width: 5rem + 2rem * 25;

.icon {
	height: 1.5rem;
	margin-right: 0.5rem;

	.grid-nav & {
		display: none;
	}
}

.grid-nav {
	display: none;
	&,
	> .container {
		background-color: #090922 !important;
		padding: 0 !important;
	}
	> .container,
	.navbar-nav {
		flex-flow: column;
	}
}

.top-nav {
	.nav-right {
		margin-inline-start: auto;
	}
}

@media (max-width: $grid-width) and (orientation: portrait) {
	.top-nav {
		display: none;
	}
	.grid-nav {
		display: flex;
	}
}

html,
body {
	background-color: #090922;
	height: 100%;
	margin: 0;
	padding: 0;
}

#app {
	background-color: #090922;
	height: 100%;
	display: flex;
	flex-flow: column;
}

.game {
	flex-grow: 1;
	display: grid;
	flex-flow: row;

	> * {
		grid-row: 1;
	}

	@media (max-width: $grid-width) and (orientation: portrait) {
		> :nth-child(5) {
			grid-column: 3;
		}
		> :nth-child(6) {
			grid-column: 2;
		}

		@for $i from 4 through 6 {
			> :nth-child(#{$i}) {
				grid-row: 2;
			}
		}
	}
}

.board {
	display: flex;
	flex-flow: column;
	margin: 0.5rem;

	@media (max-width: $small-width) {
		margin: 0.2rem;
	}

	@for $i from 1 through 5 {
		&:nth-child(#{$i})::before {
			content: 'Board #{$i}';
			font-weight: 700;
			text-align: center;
			@media (min-width: $small-width) {
				font-size: 1.2rem;
			}
		}
	}
}

.guess {
	flex-basis: 1rem;
	flex-grow: 1;

	display: flex;
	flex-flow: row;
}

.letter-container {
	transition: background-color linear 150ms;
	min-width: 1rem;
	min-height: 1rem;
	.modal-body & {
		min-width: 1.7rem;
		min-height: 1.7rem;
	}
	position: relative;
	flex-grow: 1;

	margin: 0.25rem;
	@media (max-width: $small-width) {
		margin: 0.1rem;
	}
	color: white;

	&.letter-blank {
		background-color: #111;
		border: 1px solid #444;
	}
	&.letter-grey {
		background-color: #444;
	}
	&.letter-green {
		background-color: #4ba118;
	}
	&.letter-yellow {
		background-color: #f5b811;
	}
	&.letter-blue {
		background-color: #305080;
	}
}

.letter {
	user-select: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-transform: uppercase;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	font-size: 1.5rem;
	@media (max-width: $small-width) {
		font-size: 1rem;
	}

	&.typing-letter-blue {
		color: #4486ea;
	}
	&.typing-letter-red {
		color: #e22;
	}
	&.typing-letter-inactive {
		opacity: 0.5;
	}
}

.answer {
	font-weight: 700;
	text-transform: uppercase;
}
.answer-correct {
	color: #22f044;
}
.answer-incorrect {
	color: #e55;
}

.keyboard-btn {
	position: absolute;
	bottom: 0.25rem;
	left: 50%;
	transform: translateX(-50%);

	&:not(:hover) {
		opacity: 50%;
	}
}

.keyboard-hide {
	display: none;
}
.keyboard-show {
	display: flex;
}
.keyboard-popup {
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	z-index: 1030;
	width: 100vw;
	height: 100%;
	flex-flow: column;
	align-items: center;
	justify-content: flex-end;

	.btn-close {
		position: absolute;
		top: 0.25rem;
		right: 0.25rem;
		font-size: 1.5rem;
	}

	.keyboard-input {
		margin: 1rem;
		.letter-container {
			height: 3rem;
			width: 3rem;

			.letter {
				font-size: 1.5rem;
			}
		}
	}
	.keyboard {
		width: 100%;

		.keyboard-row {
			display: flex;
			flex-flow: row;
			justify-content: center;
			height: 3rem;

			.keyboard-key {
				flex-basis: 1rem;
				max-width: 5rem;
				flex-grow: 1;

				@mixin key-color($color) {
					background-color: $color;
					border-color: $color;
					&:active,
					&:hover,
					&:focus {
						background-color: darken($color, 10%);
						border-color: darken($color, 10%);
					}
				}

				&.key-blue {
					@include key-color(#5c6399);
				}
				&.key-inactive {
					@include key-color(#444);
					color: #aaa;
				}
				&.key-green-yellow {
					@include key-color(#78c710);
				}
			}
		}
	}
}

.share-text {
	user-select: all;
	font-family: var(--bs-font-sans-serif);
}

.custom-overwrite-warning {
	color: red;
	font-weight: 700;
}

.help-dialog > .modal-dialog {
	@media (min-width: 1000px) {
		max-width: 50vw;
	}
}
